import { graphql, useStaticQuery, Link } from 'gatsby';
import React, { ReactNode } from 'react';
import 'reflect-metadata';
import Layout from '../layout';
import styled from 'styled-components';
import { BLOCKS } from "@contentful/rich-text-types"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import {  Column, Container, Row, FlexProps } from '../styles/grids';

interface MusicPageProps {
    children: ReactNode;
}

// TODO: Typify
interface MusicData {
    allContentfulMusic: {
        edges: any[];
    };
}

const MusicWorks = styled.div<FlexProps>`
  text-align: center;
`;

const StyledLink = styled(Link)`
    text-decoration: none;
    color: #F3867F;
    &:hover{
        text-decoration: underline;
        cursor: pointer;
    }

  h1 {
      font-family: Inconsolata;
      font-weight: 200;
      font-size: 30px;
      margin: 5px;
  }
`;

const MusicPage: React.FC<MusicPageProps> = () => {

    const { allContentfulMusic }: MusicData = useStaticQuery(graphql`
        query {
            allContentfulMusic(sort: {fields: date, order: DESC}) {
                edges {
                    node {
                        title
                        date
                        albumUrl
                    }
                }
            }
        }
    `);

    const ColumnProps = {
        color: '#F3867F',
        margin: '10px',
        padding: '20px',
        position: 'relative',
        overflow: 'scroll',
        marginBottom: '10vw'
    };


    return (
        <Layout>
            <Container>
                <Row>
                    <Column {...ColumnProps} flex={1}>
                        <MusicWorks>
                            {allContentfulMusic.edges.map((edge, key) => {
                                return (
                                    <StyledLink to={edge.node.albumUrl} target="_blank">
                                        <i><h1 key={key}>{edge.node.title}</h1></i>
                                    </StyledLink>
                                )
                            })}
                        </MusicWorks>
                    </Column>
                </Row>
            </Container>
        </Layout>
    );
};

export default MusicPage
